import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LoginPage, ListPage, NotFound404 } from './pages';
import { ProtectedRoute } from './components/protected-route';
import { ProvideAuth } from './services/auth';

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <ProtectedRoute path="/list">
            <ListPage />
          </ProtectedRoute>
          <Route path="/" exact={true}>
            <Redirect to="/list" />
          </Route>
          <Route>
            <NotFound404 />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}
