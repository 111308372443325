import styles from './message.module.css';
import statusImage from '../../images/status.svg';
import { useAuth } from '../../services/auth';
import { getTimeFromTimestamp } from '../../services/utils';

export const Message = ({ message }) => {
  const {
    user: { id }
  } = useAuth();
  const isOwnMessage = id.toString() === message.senderId.toString();
  const messageClassname = isOwnMessage ? styles.ownMessage : styles.message;
  const containerClassname = isOwnMessage ? styles.ownMessageContainer : styles.messageContainer;
  const status = isOwnMessage ? <img src={statusImage} alt="status" /> : null;
  return (
    <div className={containerClassname}>
      <div className={messageClassname}>
        {message.text}
        <div className={styles.messageInfo}>
          <p>{getTimeFromTimestamp(message.timestamp)}</p>
          {status}
        </div>
      </div>
    </div>
  );
};
